import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import StepNavigation from './utils/StepNavigation'; // 引入共用的步驟導航組件

const CreateStoryOutline = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goToSelfCreate = () => {
    navigate('/create-story/step3-selfCreate');
  };

  const goToAiCreate = () => {
    navigate('/create-story/step3-aiCreate');
  };

  const goBack = () => {
    navigate('/create-story');
  };

  return (
    <div className="container mx-auto p-6">

      <div className="flex items-start justify-center mb-8 relative">
        <div className="flex items-start space-x-8"> {/* 使用 space-x-8 增加間距 */}
          {/* 上一步按鈕 */}
          <div
            className="fixed bg-gray-500 text-white py-2 px-6 rounded-full text-lg font-semibold shadow-lg border-4 border-white transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer"
            style={{
              bottom: '16px',
              left: '20%',
              zIndex: 9999, // 確保按鈕在最上層
              pointerEvents: 'auto', // 確保可以點擊
            }}
            onClick={goBack}
          >
            {t('common.previous')}
          </div>
          <StepNavigation currentStep={2} />
        </div>
      </div>

      <div className="text-center mb-12">
        <h2 className="text-yellow-500 text-3xl font-bold mb-4">{t('createStoryOutline.title')}</h2>
        <p className="text-gray-300 text-lg">
          {t('createStoryOutline.description')}
        </p>
      </div>

      <div className="flex justify-center space-x-6 mb-8">
        <button className="bg-orange-500 text-white py-2 px-6 rounded-full text-lg" onClick={goToSelfCreate}>
          {t('createStoryOutline.selfCreate')}
        </button>
        <button className="bg-gray-200 text-gray-800 py-2 px-6 rounded-full text-lg" onClick={goToAiCreate}>
          {t('createStoryOutline.aiCreate')}
        </button>
      </div>

    </div>
  );
};

export default CreateStoryOutline;
