import React, { useState, useEffect, useRef } from 'react';
import { Box, Pagination } from '@mui/material';
import CompleteCategorySection from './utils/CompleteCategorySection';
import HTMLFlipBook from 'react-pageflip';
import api from '../../services/apiService';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';

const StoryListViewer = () => {
  const { t } = useTranslation();
  const [completedStories, setCompletedStories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentCategoryPage, setCurrentCategoryPage] = useState(1);
  const [images, setImages] = useState([]);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const containerRef = useRef();
  const bookRef = useRef();
  const itemsPerPage = 5;

  const loadFile = async (filename) => {
    if (filename) {
      try {
        const response = await api.get(`/api/stories/getfile/${filename}`, {
          responseType: 'blob',
        });
        const fileBlob = response.data;
        const fileUrl = URL.createObjectURL(fileBlob);
        return fileUrl;
      } catch (error) {
        console.error(t('error.loadFileFailed'), error);
        return '';
      }
    }
    return '';
  };

  useEffect(() => {
    const fetchCompletedStories = async () => {
      try {
        const response = await api.get('/api/stories/getCompletedStories');
        const stories = response.data;

        const storiesWithAssets = await Promise.all(
          stories.map(async (story) => {
            const coverImageUrl = await loadFile(story.coverImage);
            const pdfUrl = await loadFile(story.pdf_filename);
            const watermarkedPdfUrl = await loadFile(story.watermarked_pdf_filename);
            return {
              ...story,
              coverImageUrl,
              pdfUrl,
              watermarkedPdfUrl,
            };
          })
        );

        setCompletedStories(storiesWithAssets);
      } catch (error) {
        console.error(t('error.fetchStoriesFailed'), error);
      } finally {
        setLoading(false);
      }
    };

    fetchCompletedStories();
  }, []);

  const handleImageClick = async (storyListId) => {
    try {
      const response = await api.get('/api/stories/getSelectedImages', {
        params: { storyListId },
      });
      const filenames = response.data;
      const imageUrls = [];

      for (const file of filenames) {
        const { selected_image_filename } = file;

        const blobResponse = await api.get(`/api/stories/getfile/${selected_image_filename}`, {
          responseType: 'blob',
        });

        const imageUrl = URL.createObjectURL(blobResponse.data);
        imageUrls.push(imageUrl);
      }

      setImages(imageUrls);
      setIsFullScreen(true);
      toggleFullScreen();
    } catch (error) {
      console.error(t('error.loadImagesFailed'), error);
      Swal.fire({
        title: t('common.error'),
        text: t('error.imageLoadError'),
        icon: 'error',
        confirmButtonText: t('common.ok'),
      });
    }
  };

  const handleBuyClick = async (storyListId) => {
    try {
      const result = await Swal.fire({
        title: t('confirm.paymentTitle'),
        text: t('confirm.paymentText'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: t('common.confirm'),
        cancelButtonText: t('common.cancel'),
      });

      if (result.isConfirmed) {
        const response = await api.post('/api/stories/confirmPayment', { storyListId });
        const { pdfFilename } = response.data;

        const pdfUrl = await loadFile(pdfFilename);
        Swal.fire(t('payment.success'), t('payment.successMessage'), 'success');
        setCompletedStories((prevStories) =>
          prevStories.map((story) =>
            story.id === storyListId ? { ...story, pdfUrl, is_paid: true } : story
          )
        );
      }
    } catch (error) {
      Swal.fire(t('payment.failure'), t('payment.failureMessage'), 'error');
    }
  };

  const toggleFullScreen = () => {
    if (containerRef.current) {
      if (!document.fullscreenElement) {
        containerRef.current.requestFullscreen().catch((err) => {
          console.error(t('error.fullscreenError'), err.message);
        });
      } else {
        document.exitFullscreen();
      }
    }
  };

  const exitFullScreen = () => {
    setIsFullScreen(false);
    if (document.fullscreenElement) {
      document.exitFullscreen().catch((err) => {
        console.error(t('error.exitFullscreenError'), err.message);
      });
    }
  };

  const indexOfLastItem = currentCategoryPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentStories = completedStories.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div className="container mx-auto p-6">
      {loading ? (
        <p className="text-center text-gray-500">{t('common.loading')}</p>
      ) : (
        <div>
          {currentStories.map((story) => (
            <CompleteCategorySection
              key={story.id}
              title={story.title}
              description={story.description}
              books={[{ imgSrc: story.coverImageUrl, title: story.title }]}
              pdfUrl={story.is_paid ? story.pdfUrl : null}
              watermarkedPdfUrl={story.watermarkedPdfUrl}
              price={story.is_paid ? null : story.price}
              isPaid={story.is_paid}
              updatedAt={story.updated_at}
              onImageClick={() => handleImageClick(story.id)}
              onBuyClick={() => handleBuyClick(story.id)}
            />
          ))}

          <Box display="flex" justifyContent="center" alignItems="center" mb={8}>
            <Pagination
              count={Math.ceil(completedStories.length / itemsPerPage)}
              page={currentCategoryPage}
              onChange={(event, value) => setCurrentCategoryPage(value)}
              variant="outlined"
              shape="rounded"
              sx={{
                '& .MuiPaginationItem-root': {
                  color: '#ffffff',
                  borderColor: '#D3D3D3',
                },
                '& .Mui-selected': {
                  backgroundColor: '#00bcd4',
                  color: '#ffffff',
                  borderColor: '#00bcd4',
                },
                '& .MuiPaginationItem-root:hover': {
                  backgroundColor: '#424242',
                },
              }}
            />
          </Box>

          {isFullScreen && (
            <div ref={containerRef} className="flex justify-center mb-8">
              <button
                onClick={exitFullScreen}
                style={{
                  position: 'absolute',
                  top: '20px',
                  right: '40px',
                  color: '#fff',
                  fontSize: '24px',
                  background: 'transparent',
                  border: 'none',
                  cursor: 'pointer',
                }}
              >
                {t('common.close')}
              </button>
              <HTMLFlipBook
                width={400}
                height={600}
                size="stretch"
                drawShadow={true}
                flippingTime={1000}
                usePortrait={true}
                startZIndex={0}
                autoSize={true}
                maxShadowOpacity={0.5}
                showCover={true}
                mobileScrollSupport={true}
                ref={bookRef}
              >
                {images.map((imageUrl, index) => (
                  <div key={index} className="demoPage">
                    <img
                      src={imageUrl}
                      alt={`${t('story.page')} ${index + 1}`}
                      style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                  </div>
                ))}
              </HTMLFlipBook>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default StoryListViewer;
