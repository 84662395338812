import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import StepNavigation from './utils/StepNavigation';

const Step3_selfCreate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  useEffect(() => {
    const selectedTitle = JSON.parse(localStorage.getItem('selectedTitle'));

    if (selectedTitle && selectedTitle.category_id === 9999) {
      setTitle(selectedTitle.title);
      setDescription(selectedTitle.description);
    }
  }, []);

  const goToNextStep = () => {
    if (!title || !description) {
      Swal.fire({
        icon: 'error',
        title: t('error.title'),
        text: t('error.needFillinAllFields'),
        confirmButtonText: t('error.confirmButtonText'),
      });
      return;
    }

    const newStory = {
      id: 9999,
      category_id: 9999,
      title: title,
      description: description,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      categoryId: 9999,
    };

    localStorage.setItem('selectedTitle', JSON.stringify(newStory));

    navigate('/create-story/step4-storyList');
  };

  const goBack = () => {
    navigate('/create-story/step2-choiceType');
  };

  return (
    <div className="container mx-auto p-6">
      <div className="relative">
        <StepNavigation currentStep={3} />
      </div>

      <div className="text-center mb-12">
        <h2 className="text-yellow-500 text-3xl font-bold mb-4">{t('step3_selfCreate.title')}</h2>
        <p className="text-gray-300 text-lg">{t('step3_selfCreate.description')}</p>
      </div>

      <div className="mb-8">
        <input
          type="text"
          className="w-full p-4 border border-gray-300 rounded-lg mb-4"
          placeholder={t('step3_selfCreate.placeholderTitle')}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          style={{
            backgroundColor: '#222222',
            color: '#ffffff',
            borderColor: '#888888',
          }}
        />
        <textarea
          className="w-full h-64 p-4 border border-gray-300 rounded-lg"
          placeholder={t('step3_selfCreate.placeholderDescription')}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          style={{
            backgroundColor: '#222222',
            color: '#ffffff',
            borderColor: '#888888',
          }}
        ></textarea>
      </div>

      {/* 將「上一步」按鈕固定在左下角，「下一步」按鈕固定在右下角 */}

      <div className="flex items-start justify-center mb-8 relative">
        <div className="flex items-start space-x-8">{/* 上一步按鈕 */}
          <div
            className="fixed bg-gray-500 text-white py-2 px-6 rounded-full text-lg font-semibold shadow-lg border-4 border-white transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer"
            style={{
              bottom: '16px',
              left: '20%',
              zIndex: 9999, // 確保在最上層
              pointerEvents: 'auto', // 確保可點擊
            }}
            onClick={goBack}
          >
            {t('common.previous')}
          </div>

          {/* 下一步按鈕 */}
          <div
            className="fixed bg-gradient-to-r from-teal-400 to-blue-500 text-white py-2 px-6 rounded-full text-lg font-semibold shadow-lg border-4 border-white transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer"
            style={{
              bottom: '16px',
              right: '20%',
              zIndex: 9999, // 確保在最上層
              pointerEvents: 'auto', // 確保可點擊
            }}
            onClick={goToNextStep}
          >
            {t('common.next')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step3_selfCreate;
