import React from 'react';
import { useTranslation } from 'react-i18next';

const CompleteCategorySection = ({ title, description, books, pdfUrl, watermarkedPdfUrl, price, isPaid, updatedAt, onImageClick, onBuyClick }) => {
  const { t } = useTranslation();

  return (
    <div className="mb-12 p-4 bg-gray-900 rounded-lg shadow-md hover:shadow-lg transition-shadow">
      <div className="flex flex-col md:flex-row items-center">
        {/* 封面圖片區域 */}
        <div
          className="bg-gray-800 p-6 rounded-lg shadow-lg transform hover:scale-105 transition-transform cursor-pointer"
          onClick={() => onImageClick(books[0].imgSrc)}
          style={{
            width: '100%',
            maxWidth: '200px', // 最大寬度限制
            aspectRatio: '2 / 3', // 確保 2:3 的比例
            overflow: 'hidden',
          }}
        >
          <img src={books[0].imgSrc} alt={books[0].title} className="w-64 h-64 object-cover rounded-lg" />
        </div>

        {/* 右側描述區域 */}
        <div className="flex flex-col items-start md:ml-8 mt-4 md:mt-0 text-gray-100">
          <h2 className="text-3xl font-bold mb-2">{title}</h2>
          <p className="text-md text-gray-400 mb-4">{description}</p>

          {/* PDF 下載按鈕 */}
          {isPaid ? (
            <a href={pdfUrl} className="bg-blue-500 text-white py-2 px-6 rounded-full text-lg mb-2" download>
              {t('category.downloadPdf')}
            </a>
          ) : (
            <div className="flex items-center space-x-4 mb-4">
              <span className="text-lg text-gray-300">{t('category.price')}: {price} {t('category.currency')}</span>
              <button onClick={onBuyClick} className="bg-green-500 text-white py-2 px-6 rounded-full text-lg">
                {t('category.buy')}
              </button>
            </div>
          )}

          {/* 浮水印PDF的按鈕，如果已付費則不顯示 */}
          {!isPaid && watermarkedPdfUrl && (
            <a href={watermarkedPdfUrl} className="bg-gray-500 text-white py-2 px-6 rounded-full text-lg mb-2" download>
              {t('category.downloadWatermarkedPdf')}
            </a>
          )}

          {/* 更新日期 */}
          <p className="text-md text-gray-400 mt-2">{t('category.updatedAt')}: {new Date(updatedAt).toLocaleDateString()}</p>
        </div>
      </div>
    </div>
  );
};

export default CompleteCategorySection;
