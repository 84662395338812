import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import StepNavigation from './utils/StepNavigation';
import api from '../../services/apiService';
import ClipLoader from 'react-spinners/ClipLoader';
import Swal from 'sweetalert2';
import PaymentModal from '../balance/PaymentModal';

const Step4_storyList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [storyData, setStoryData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  useEffect(() => {
    const fetchStory = async () => {
      const savedStoryData = JSON.parse(localStorage.getItem('storyData'));
      const selectedTitle = JSON.parse(localStorage.getItem('selectedTitle'));
      const basicInfo = JSON.parse(localStorage.getItem('basicInfo'));

      const missingParams = [];
      if (!selectedTitle) missingParams.push(t('error.missingSelectedTitle'));
      if (!basicInfo?.userName) missingParams.push(t('error.missingUserName'));
      if (!basicInfo?.englishName) missingParams.push(t('error.missingEnglishName'));
      if (!basicInfo?.age) missingParams.push(t('error.missingAge'));
      if (basicInfo?.imageFilenames.some(link => !link)) missingParams.push(t('error.missingImageLinks'));

      if (missingParams.length > 0) {
        setErrorMessage(`${t('error.missingParams')}: ${missingParams.join('、')}`);
        setLoading(false);
        return;
      }

      const introduction = basicInfo?.introduction || '';
      const personalMessage = basicInfo?.personalMessage || '';

      if (savedStoryData) {
        if (savedStoryData.storyList?.title_id !== selectedTitle.id) {
          const result = await Swal.fire({
            icon: 'warning',
            title: t('step4_storyList.mismatchTitle'),
            text: t('step4_storyList.refreshConfirmation'),
            showCancelButton: true,
            confirmButtonText: t('common.yes'),
            cancelButtonText: t('common.no')
          });

          if (result.isConfirmed) {
            localStorage.removeItem('storyData');
            window.location.reload();
            return;
          }
        }
        setStoryData(savedStoryData);
        setLoading(false);
      } else {
        try {
          const response = await api.post('/api/stories/getstorylist', {
            titleId: selectedTitle.id,
            title: selectedTitle.title,
            description: selectedTitle.description,
            userName: basicInfo.userName,
            userEnglishName: basicInfo.englishName,
            age: basicInfo.age,
            imageLinks: basicInfo.imageFilenames,
            introduction,
            personalMessage
          });

          if (response.data) {
            setStoryData(response.data);
            localStorage.setItem('storyData', JSON.stringify(response.data));
            if (response.data.storyList?.id) {
              localStorage.setItem('storyListId', response.data.storyList.id);
            }
          }
        } catch (error) {
          console.error(t('error.fetchFailed'), error);
          setErrorMessage(t('error.fetchErrorMessage'));
        } finally {
          setLoading(false);
        }
      }
    };

    fetchStory();
  }, [t]);

  const handleInputChange = (value, chapterIndex, key) => {
    setStoryData(prev => {
      const updatedStoryData = { ...prev };
      updatedStoryData.storyChapters[chapterIndex][key] = value;
      return updatedStoryData;
    });
  };

  const handleInputBlur = (value, chapterIndex, key) => {
    const originalValue = JSON.parse(localStorage.getItem('storyData')).storyChapters[chapterIndex][key];

    if (value !== originalValue) {
      const updatedChapterData = {
        [key]: value,
        prompt: '',
        'eng_description': '',
        storyboard: ''
      };

      localStorage.setItem('storyData', JSON.stringify(storyData));
      updateStoryChapter(
        storyData.storyList.id,
        storyData.storyChapters[chapterIndex].chapter_number,
        updatedChapterData
      );
    }
  };

  const updateStoryChapter = async (storyListId, chapterNumber, updatedChapterData) => {
    try {
      const response = await api.post('/api/stories/updatestorychapter', {
        storyListId,
        chapterNumber,
        updatedChapterData
      });
      if (response.data) {
        console.log(t('common.chapterUpdated'));
      }
    } catch (error) {
      console.error(t('error.updateFailed'), error);
    }
  };

  const checkQuota = async () => {
    try {
      const response = await api.get('/api/balance/checkFreeQuota');
      const { result, title, reason } = response.data;

      if (!result) {
        await Swal.fire({
          icon: 'warning',
          title,
          text: reason,
          confirmButtonText: '確認',
          showCancelButton: true,
          cancelButtonText: t('common.recharge')
        }).then((res) => {
          if (res.isDismissed) {
            setShowPaymentModal(true);
          }
        });
        return false;
      }
      return true;
    } catch (error) {
      console.error('檢查免費額度失敗', error);
      return false;
    }
  };

  const goToNextStep = async () => {
    const quotaAvailable = await checkQuota();
    if (quotaAvailable) {
      navigate('/create-story/step5-picView');
    }
  };

  const goBack = () => {
    const selectedTitle = JSON.parse(localStorage.getItem('selectedTitle'));

    if (selectedTitle && selectedTitle.category_id === 9999) {
      navigate('/create-story/step3-selfCreate');
    } else {
      navigate('/create-story/step3-aiCreate');
    }
  };

  const refreshStoryData = () => {
    localStorage.removeItem('storyData');
    window.location.reload();
  };

  const getTitleByChapterNumber = (chapterNumber) => {
    switch (chapterNumber) {
      case -1: return t('step4_storyList.cover');
      case 0: return t('step4_storyList.introduction');
      case 998: return t('step4_storyList.thankYouPage');
      case 999: return t('step4_storyList.backCover');
      default: return null;
    }
  };

  return (
    <div className="container mx-auto p-6">
      {showPaymentModal && (
        <PaymentModal isOpen={showPaymentModal} toggle={() => setShowPaymentModal(false)} />
      )}

      <div className="flex items-start justify-center mb-8 relative">
        <div className="flex items-start space-x-8">
          {/* 上一步按鈕 */}
          <div
            className="fixed bg-gray-500 text-white py-2 px-6 rounded-full text-lg font-semibold shadow-lg border-4 border-white transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer"
            style={{
              bottom: '16px',
              left: '20%',
              zIndex: 9999, // 確保在最上層
              pointerEvents: 'auto', // 確保可點擊
            }}
            onClick={goBack}
          >
            {t('common.previous')}
          </div>

          {/* 中間導航 */}
          <StepNavigation currentStep={4} />

          {/* 下一步按鈕 */}
          <div
            className="fixed bg-gradient-to-r from-teal-400 to-blue-500 text-white py-2 px-6 rounded-full text-lg font-semibold shadow-lg border-4 border-white transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer"
            style={{
              bottom: '16px',
              right: '20%',
              zIndex: 9999, // 確保在最上層
              pointerEvents: 'auto', // 確保可點擊
            }}
            onClick={goToNextStep}
          >
            {t('common.next')}
          </div>
        </div>
      </div>

      <div className="text-center mb-8">
        {storyData?.storyList?.title && (
          <div className="bg-gradient-to-r from-teal-400 to-blue-500 text-white p-4 rounded-lg shadow-lg inline-block mb-6">
            <h3 className="text-3xl font-bold">{storyData.storyList.title}</h3>
          </div>
        )}
        <h2 className="text-xl font-medium text-gray-300">{t('step4_storyList.title')}</h2>
      </div>

      {loading ? (
        <div className="flex justify-center items-center">
          <ClipLoader color="#00bcd4" loading={loading} size={50} />
          <span className="text-white text-lg ml-4">{t('common.loading')}</span>
        </div>
      ) : errorMessage ? (
        <div className="text-red-500 text-lg text-center">{errorMessage}</div>
      ) : (
        <div className="bg-gray-800 text-gray-300 p-6 rounded-lg shadow-lg mb-8">
          {storyData?.storyChapters
            .filter(chapter => chapter.chapter_number !== 1000)
            .map((chapter, index) => (
              <div key={index} className="mb-8">
                {chapter.chapter_number > 0 && chapter.chapter_number < 998 ? (
                  <span className="text-gray-400">{`P:${chapter.chapter_number}`}</span>
                ) : (
                  <h4 className="text-lg text-white">{getTitleByChapterNumber(chapter.chapter_number)}</h4>
                )}
                <textarea
                  className="w-full p-3 border border-gray-600 rounded-lg bg-gray-700 text-gray-200 resize-none mt-2"
                  rows="3"
                  value={chapter.description || ''}
                  onChange={(e) => handleInputChange(e.target.value, index, 'description')}
                  onBlur={(e) => handleInputBlur(e.target.value, index, 'description')}
                />
              </div>
            ))}
        </div>
      )}

      <div className="flex justify-center items-center">
        <button className="bg-blue-500 text-white py-2 px-6 rounded-full text-lg" onClick={refreshStoryData}>
          {t('step4_storyList.refresh')}
        </button>
      </div>
    </div>
  );
};

export default Step4_storyList;
